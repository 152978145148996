@import 'variables';
@import '~@cigna/cigna_digital_dcws_cigna-frontend/src/scss/cigna-frontend';
@import './components/list-groups';
@import './components/program-finder';
@import './components/hero';

.table {
  th,
  td {
    padding: 1rem;
  }
}
